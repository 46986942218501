window.obaia.Components.rave = function (options = {}) {

    const audioElement = document.getElementById('audioElement')
    let animationFrameIdRave
    return {
        rave: 'off',
        audioContext: null,
        analyzer: null,
        track: null,
        dataArray: [],
        init() {
            this.meshgroupPosition(-1, 0, 0)
            this.setStrength(0.5)
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
            this.track = this.audioContext.createMediaElementSource(audioElement)
            this.analyzer = this.audioContext.createAnalyser()
            console.log(this.analyzer)
            this.analyzer.fftSize = 256 // Use a large fftSize for detailed frequency data
            this.track.connect(this.analyzer)
            this.analyzer.connect(this.audioContext.destination)
            this.dataArray = new Uint8Array(this.analyzer.frequencyBinCount)
        },
        go404() {
            // this.meshgroupPosition(0, 0, 0)
            this.rave = 'on'
            this.audioContext.resume()
            audioElement.currentTime = 69
            audioElement.play() // Start playing the audio
            const ballToTheBeatFunction = () => {
                animationFrameIdRave = requestAnimationFrame(ballToTheBeatFunction.bind(this));
                // requestAnimationFrame(ballToTheBeat) // Continuously animate
                this.analyzer.getByteFrequencyData(this.dataArray) // Get current frequency data
                // Calculate the average frequency value to determine the beat strength
                const average = this.dataArray.reduce((acc, val) => acc + val, 0) / this.dataArray.length
                this.setStrength(average) // Adjust scaling as needed
            }
            ballToTheBeatFunction() // Start the animation

            setTimeout(() => {
                audioElement.pause() // Start playing the audio
                audioElement.currentTime = 69
                cancelAnimationFrame(animationFrameIdRave)
                this.rave = 'off'
                this.setStrength(1)
                // this.meshgroupPosition(0, -2, 0)
            }, 14000)
        }
    }
}